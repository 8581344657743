import React, { useCallback, Fragment } from "react";
import { useHistory, useLocation } from "react-router-dom";
import isNil from "lodash/isNil";

import Dialog from "@ui-kit/Dialog";
import Typography from "@ui-kit/Typography";
import Button from "@ui-kit/Button";
import { Spacer } from "@ui-kit/Spacer";

import { isAdmin } from "@selectors";
import { useUserStore } from "@store/UserStore";
import { BoolItem } from "@pages/DeepDivePanel/components/BoolItem";
import { useDeepDivePanelStore } from "@hooks/useDeepDivePanelStore";
import { useCss } from "./styles";
import { Models } from "@services/api";
import { format_number } from "@helpers/format_number";

const MealsAndNutrition: React.FC = () => {
  const history = useHistory();
  const { search } = useLocation();
  const [{ facility }] = useDeepDivePanelStore();
  const [{ user }] = useUserStore();
  const css = useCss();

  const closeDialog = useCallback(() => {
    history.replace({
      pathname: `/map/${facility.id}`,
      search,
    });
  }, [history, search]);

  return (
    <Dialog
      open
      onClose={closeDialog}
      data-test="meals-and-nutrition-dialog"
      tier={facility.subscriptionTier}
      title="Meals & nutrition"
    >
      <div css={css.root}>
        <BoolItem
          label="Outside food allowed"
          state={facility.nutritionInfo?.outsideFoodAllowed}
          data-test="outsideFoodAllowed-display"
        />
        <BoolItem
          label="Peanut free facility"
          state={facility.nutritionInfo?.peanutFreeFacility}
          data-test="peanutFreeFacility-display"
        />
        <BoolItem
          label="Gluten free facility"
          state={facility.nutritionInfo?.glutenFreeFacility}
          data-test="glutenFreeFacility-display"
        />

        <Spacer size="medium" />

        <Typography gutterBottom>
          <Typography bolded variant="inherit">
            Sample menu:
          </Typography>{" "}
          {facility.nutritionInfo?.sampleMenuFile?.original ? (
            <a
              href={facility.nutritionInfo?.sampleMenuFile?.original}
              target="_blank"
              className="g-link"
              data-test="sampleMenuFile-link"
              rel="noreferrer"
            >
              click to view
            </a>
          ) : (
            <Typography variant="inherit" color="textSecondary">
              not available
            </Typography>
          )}
        </Typography>

        <Typography gutterBottom data-test="meal-fees-display">
          <Typography bolded variant="inherit">
            Meal plan:
          </Typography>{" "}
          {facility.nutritionInfo?.costIsIncludedInTuition
            ? "included in price of tuition"
            : !isNil(facility.nutritionInfo?.costFrom) ||
              !isNil(facility.nutritionInfo?.costTo)
            ? [
                !isNil(facility.nutritionInfo?.costFrom) &&
                  format_number(facility.nutritionInfo.costFrom / 100, {
                    format: "$0,0",
                  }),
                facility.nutritionInfo?.costFrom &&
                  facility.nutritionInfo?.costTo &&
                  facility.nutritionInfo?.costFrom !==
                    facility.nutritionInfo?.costTo &&
                  "-",
                !isNil(facility.nutritionInfo?.costTo) &&
                  facility.nutritionInfo?.costFrom !==
                    facility.nutritionInfo?.costTo &&
                  format_number(facility.nutritionInfo.costTo / 100, {
                    format: "$0,0",
                  }),
              ]
                .filter(Boolean)
                .join(" ")
            : "---"}
        </Typography>

        {facility.nutritionInfo.mealsCatered && (
          <Typography gutterBottom data-test="meal-catered-display">
            <Typography bolded variant="inherit">
              Meal prep:
            </Typography>{" "}
            meals are catered
          </Typography>
        )}

        <Spacer size="medium" />

        <BoolItem
          label="Meals served"
          state={facility.nutritionInfo?.mealsServed}
          data-test="mealsServed-display"
        />

        <BoolItem
          label="Breakfast served"
          greyEmpty
          state={
            facility.nutritionInfo?.mealsServed
              ? facility.nutritionInfo.mealsServedBreakfast
              : null
          }
          data-test="mealsServedBreakfast-display"
        />
        <BoolItem
          label="Morning snack served"
          greyEmpty
          state={
            facility.nutritionInfo?.mealsServed
              ? facility.nutritionInfo.mealsServedMorningSnack
              : null
          }
          data-test="mealsServedMorningSnack-display"
        />
        <BoolItem
          label="Lunch served"
          greyEmpty
          state={
            facility.nutritionInfo?.mealsServed
              ? facility.nutritionInfo.mealsServedLunch
              : null
          }
          data-test="mealsServedLunch-display"
        />
        <BoolItem
          label="Afternoon snack served"
          greyEmpty
          state={
            facility.nutritionInfo?.mealsServed
              ? facility.nutritionInfo.mealsServedAfternoonSnack
              : null
          }
          data-test="mealsServedAfternoonSnack-display"
        />

        {!!facility.nutritionInfo?.mealsServedDescription && (
          <Fragment>
            <Spacer size="small" />
            <Typography italic data-test="mealsServedDescription-display">
              {facility.nutritionInfo.mealsServedDescription}
            </Typography>
          </Fragment>
        )}

        {(facility.isEditable || isAdmin(user)) && (
          <>
            <Spacer size="md" />
            <Button
              color={
                facility.subscriptionTier === Models.SubscriptionTier.Pro
                  ? "black"
                  : "primary"
              }
              variant="outlined"
              fullWidth
              size="large"
              link={`/map/${facility.id}/edit/meals-and-nutrition`}
            >
              Edit meals & nutrition
            </Button>
          </>
        )}
      </div>
    </Dialog>
  );
};

export default MealsAndNutrition;
