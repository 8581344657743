import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const useCss = (): Record<Classes, Interpolation<ITheme>> => ({
  root: (theme) => ({
    [theme.mediaRequests.notMobile]: {
      width: 450,
    },
  }),

  photosButton: {
    display: "flex",
    margin: "0 auto",
  },
});
